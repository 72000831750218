import { authService } from 'src/services';
import decode from 'jwt-decode';
import { Navigate } from 'react-router-dom';

export default function withPermission(Component: any, routePermission: string) {
    return function WithPermission(props) {
        const { permissionToken } = authService.getUserInfo();
        const isLoggedIn = authService.isLoggedIn()
        const decodedData: any = permissionToken ? decode(permissionToken) : {};
        let hasPermission;
        if (permissionToken && decodedData) {
            if (decodedData.permissions.find((userPermission) => userPermission === "*")) {
                hasPermission = true;
            } else {
                hasPermission = decodedData.permissions.find(userPermission => userPermission === routePermission) ? true : false
            }
        }
        else hasPermission = false

        if (!isLoggedIn) {
            return <Navigate to='/login' />;
        }
        if (!hasPermission) {
            return <Navigate to='/not-allowed' />;
        }
        return (
            <Component />
        )
    }
}
