export default function OrderSummary({ orderSummary }) {
    return (
        <>
            <dl className="space-y-6 py-6 px-4 sm:px-6 border rounded-md">
                <p className='text-xs mb-5 font-semibold uppercase mt-2'>Order summary</p>

                <div className="flex items-center justify-between">
                    <dt className="text-sm">Sub total</dt>
                    <dd className="text-sm font-medium text-gray-900">৳{orderSummary?.subTotal || 0}</dd>
                </div>
                <div className="flex items-center justify-between">
                    <dt className="text-sm">Delivery charge</dt>
                    <dd className="text-sm font-medium text-gray-900">৳{orderSummary?.deliveryCharge || 0}</dd>
                </div>
                <div className="flex items-center justify-between">
                    <dt className="text-sm">Discount</dt>
                    <dd className="text-sm font-medium text-gray-900">৳{orderSummary?.discount || 0}</dd>
                </div>
                <div className="flex items-center justify-between">
                    <dt className="text-sm">Coupon discount</dt>
                    <dd className="text-sm font-medium text-gray-900">৳{orderSummary?.couponDiscount || 0}</dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                    <dt className="text-base font-medium">Total</dt>
                    <dd className="text-base font-medium text-gray-900">৳{orderSummary?.total || 0}</dd>
                </div>
            </dl>
        </>
    )
}
