import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useVariants } from "src/hooks/variants/useVariants";
import { QueryParamsType } from "src/types/query-params";
import VariantOptionBox from "./VariantOptionBox";
import _ from "lodash";
import classNames from "classnames";
import { productService } from "src/services/product.service";

export default function Variants() {
  const productPersistedFormData = productService.getPesistedProductFormData();
  const { setFieldValue, errors, touched, values } = useFormikContext<any>();
  const [selectedVariants, setSelectedVariants] = React.useState(
    productPersistedFormData ? productPersistedFormData.variantLabels : [],
  );

  const [manageVariants, setManageVariants] = useState(
    productPersistedFormData
      ? { ...productPersistedFormData.manageVatiants }
      : {},
  );

  useEffect(() => {
    setFieldValue("variantLabels", selectedVariants);
  }, [selectedVariants]);

  const query: Record<string, QueryParamsType> = {
    limit: 40,
    page: 1,
  };

  const { variants, isLoading, mutate } = useVariants(query);
  useEffect(() => {
    mutate();
  }, [mutate]);

  if (isLoading) return null;

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const data = variants.find((variant) => variant.id === value)
    const obj = {}
    obj['label'] = data.title;
    obj['value'] = data.id;

    setSelectedVariants(prev => [...prev, { ...obj }]);
  };

  const modifiedVariants = variants
    .filter(variant => variant.isActive === true)
    .map(activeVariant => {
      return {
        label: activeVariant.title,
        value: activeVariant.id,
      };
    });

  const filtered = _.xorBy(modifiedVariants, selectedVariants, "value");

  const errorsArray = [];
  if (errors && touched) {
    Object.values(errors).map(error => {
      errorsArray.push(error);
    });
  }

  return (
    <>
      <div
        className={classNames(
          selectedVariants.length > 0 ? `h-auto overflow-y-auto` : null,
        )}
      >
        {selectedVariants.length > 0
          ? selectedVariants.map(item => {
            return (
              <VariantOptionBox
                variant={item}
                config={{
                  selectedVariants,
                  setSelectedVariants,
                  manageVariants,
                  setManageVariants,
                }}
              />
            );
          })
          : null}
      </div>

      {filtered.length > 0 ? <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">select variant</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Select variant"
          onChange={handleChange}
        >
          {filtered.map(item => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>;
          })}
        </Select>
      </FormControl> : null}

      {errors.variants && touched.variants ? (
        <p className="text-red-600">{errors.variants}</p>
      ) : null}
    </>
  );
}
