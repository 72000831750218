import {
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Popover,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import CustomDropDown from "src/generic-ui/CustomDropDown";
import CustomPagination from "src/generic-ui/CustomPagination/CustomPagination";
import { usePermissionFilter } from "src/hooks/acl/permission/usePermissionFilter";
import { useUserFilter } from "src/hooks/user/useUserFilter";
import { IUser, IUserDetails } from "src/types";
import { IPermission, IPermissionType } from "src/types/acl";

interface ISelectUser {
    onClick?: (product: any) => void;
    selectedUser?: any;
    config?: {
        mutateAvailablePermissions: any;
    };
}

export default function SearchUser({
    onClick,
    selectedUser,
    config
}: ISelectUser) {
    const {
        isLoading,
        meta,
        users,
        error,
        showResetAllButton,
        onReset,
        total,
        setPageSize,
        searchTerm,
        setSearchTerm,
        page,
        setPage,
        pageSize,
        mutate,
    } = useUserFilter();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
        null,
    );

    const [operation, setOperation] = useState<string>("")
    const { handleBlur, handleChange, values, handleSubmit, submitForm, setFieldValue } =
        useFormik({
            initialValues: {
                permissions: []
            },
            onSubmit: async (values, helper) => {
                // if (operation === "assign") {
                //     onAddPermission({ values, helper, roleId })
                // }
                // else if (operation === "unassign") {
                //     onRemovePermission({ values, helper, roleId })
                // }
            },
        });

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOk = () => {
        setAnchorEl(null);
        submitForm();
    }

    const handleSearchChange = e => {
        setSearchTerm(e.target.value);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    background: "white",
                    position: "sticky",
                    top: 0,
                    bottom: 20,
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    zIndex: 5,
                }}
            >
                <TextField
                    fullWidth
                    label="Search customer"
                    name="searchTerm"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                {!isLoading && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "1rem",
                        }}
                    >
                        {showResetAllButton ? (
                            <Button
                                className="bg-red-400 hover:bg-red-800 text-white"
                                onClick={e => onReset()}
                            >
                                Reset All
                            </Button>
                        ) : (
                            <div></div>
                        )}

                        <CustomPagination
                            onPageChange={(nexPageNumber: number) => {
                                setPage(nexPageNumber);
                            }}
                            totalCount={total}
                            pageSize={pageSize}
                            currentPage={page}
                        />
                    </Box>
                )}
            </Box>

            {isLoading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : !users.length ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    No User Found
                </Box>
            ) : (
                <Box className="overscroll-auto " height={"auto"}>
                    <Grid container spacing={2}>
                        {users.map((user: IUserDetails, key: number) => {
                            return (
                                <Grid item xs={4}>
                                    <div className="border rounded-md py-6 px-4 text-center">
                                        <p><b>Name: </b> {user?.firstName && user?.lastName ? user?.firstName + " " + user?.lastName : "-"}</p>
                                        <p><b>Phone Number:</b> {user?.phoneNumber ? user?.phoneNumber : "-"}</p>
                                        {selectedUser?.id === user.id ? <Button className="bg-red-500 text-white hover:bg-red-700 h-8 mt-2" onClick={() => {
                                            onClick({})
                                        }
                                        }>Remove</Button> : <Button className="bg-indigo-500 text-white hover:bg-indigo-700 h-8 mt-2" onClick={() => {
                                            onClick(user)
                                        }
                                        }>Add</Button>}
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Typography sx={{ paddingY: "1rem", paddingX: "1rem" }}>
                    {operation === "assign" ? <>Do you want to add the permission for this role?</> : <>Do you want to remove the permission for this role?</>}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleOk}>Yes</Button>
                </Box>
            </Popover>

        </Box>
    );
}
