/*eslint-disable*/
import axios, { AxiosResponse } from 'axios';
import { ResponseErrorType, ResponseSccessType } from 'src/types/response';
import { getBaseUrl } from '../configs/envConfig';

const API_BASE_URL = getBaseUrl();
const axiosInstance = (token = null) => {
	const instance = axios.create();

	instance.defaults.headers.post['Content-Type'] = 'application/json';
	instance.defaults.headers['Accept'] = 'application/json';

	instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	instance.defaults.timeout = 60000;
	instance.defaults.baseURL = API_BASE_URL;
	const authData = !!localStorage?.getItem('auth') ? JSON.parse(localStorage.getItem('auth') || '') : '';
	if (!!authData?.accessToken) {
		token = authData.accessToken;
	}
	if (!!token) {
		instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	}

	instance.interceptors.request.use(
		function (config) {
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		// @ts-ignore
		function (response: AxiosResponse<any, any>) {
			const responseObject: ResponseSccessType = {
				success: true,
				statusCode: response?.data?.statusCode,
				message: response?.data?.message,
				data: response?.data?.data,
				meta: response?.data?.meta,
			};
			return responseObject;
		},
		function (error) {
			const responseObject: ResponseErrorType = {
				success: false,
				statusCode: error?.response?.data?.statusCode || 500,
				message: error?.response?.data?.message || 'Something Went Wrong',
				errorMessages: error?.response?.data?.message,
			};

			return Promise.reject(responseObject);
		}
	);
	return instance;
};
export default axiosInstance;
