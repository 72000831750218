import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { DataTable } from "src/generic-ui/DataTable";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Switch from "src/generic-ui/Switch";
import { useProductFilter } from "src/hooks/products/useProductFilter";
import { authService, logger } from "src/services";
import { productService } from "src/services/product.service";
import { IOrderItem, IProduct } from "src/types";
import FilterSidebar from "./FilterSidebar";
import { CustomColCheckBox } from "./CustomCol";
import utils from "src/helpers/Utils";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import Sidebar from "./Sidebar";
import { notifyError } from "src/generic-ui";
import ActionBar from "src/generic-ui/ActionBar";
import ReorderIcon from '@mui/icons-material/Reorder';
import ReorderSidebar from "./ReorderSidebar";

const ColValue = ({ value = "" }: { value: any }) => {
  return <Box>{value}</Box>;
};

function ProductList() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [isFilterModalVisible, setFilterModal] = useState<boolean>(false);
  const [isReorderSidebarVisible, setReorderSidebarVisible] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct[]>([]);
  const [selectedId, setSelected] = useState<string | null>(null);
  const [isBulkUpdate, setBulkUpdate] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const values = useProductFilter();
  const userPermissions = authService.getUserPermissions()
  const {
    isLoading,
    products,
    meta,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    pageSize,
    setPageSize,
    mutate,
    showResetAllButton,
    onReset,
  } = values;
  const onToggleSideBar = () => setVisible(prev => !prev);
  const onToggleFilter = () => setFilterModal(prev => !prev);
  const onToggleReorderSideBar = () => setReorderSidebarVisible(prev => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);
  const handleOnCellClick = ({ row }: { row: IProduct }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const handleOnCellClickReorder = ({ row }: { row: IProduct }) => {
    setSelected(row.id);
    setReorderSidebarVisible(true)
  };

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      meta?.total !== undefined ? meta?.total : prevRowCountState,
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
    setBulkUpdate(false);
  };

  const onCloseReorderSidebar = () => {
    onToggleReorderSideBar();
    setSelected(null);
  };

  const onCloseFilterModal = () => {
    onToggleFilter();
  };

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await productService.updateProduct(id, { [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const onCheckProduct = (isChecked: boolean, product: IProduct) => {
    const index = selectedProduct.findIndex(p => p.id === product.id);
    const products = utils.deepClone(selectedProduct);
    if (index === -1) {
      products.push(product);
    } else {
      products.splice(index, 1);
    }
    setSelectedProduct(products);
  };

  const onBulkUpdate = () => {
    if (selectedProduct.length === 1) {
      setBulkUpdate(false);
      setSelected(selectedProduct[0].id);
    } else {
      setBulkUpdate(true);
    }
    onToggleSideBar();
  };

  const columns: any = [
    {
      field: "checkBox",
      headerName: "",
      flex: 1,
      renderCell: ({ row }: { row: IProduct }) => {
        return (
          <CustomColCheckBox
            product={row}
            selectedProduct={selectedProduct}
            onCheckProduct={onCheckProduct}
          />
        );
      },
    },
    { field: "code", headerName: "Product ID", flex: 1 },
    {
      field: "title",
      headerName: "Title",
      renderCell: ({ row }: { row: IProduct }) => (
        <ColValue value={row.title} />
      ),
      flex: 1,
    },
    {
      field: "featured",
      headerName: "Featured",
      renderCell: ({ row }: { row: IProduct }) => (
        <ColValue value={row.isFeatured ? "Yes" : "No"} />
      ),
      flex: 1,
    },

    {
      field: "mrp",
      headerName: "Mrp",
      flex: 1,
    },

    {
      field: "stock",
      headerName: "Stock",
      flex: 1,
    },
    {
      field: "totalRating",
      headerName: "Total Rating",
      renderCell: ({ row }: { row: IProduct }) => (
        <ColValue value={row?.stats && row?.stats?.totalRating > 0 ? row?.stats?.totalRating : "-"} />
      ),
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      flex: 2,
      renderCell: ({ row }: { row }) => {
        return (
          <div className="">
            <div className="py-2 px-3 border">
              <div>
                <label htmlFor="">Active</label>
                <Switch
                  isChecked={!!row.isActive}
                  showPopOver={true}
                  onClick={(isChecked: boolean) =>
                    updateElement({
                      keyName: "isActive",
                      value: isChecked,
                      id: row.id,
                    })
                  }
                />
              </div>

              <div>
                <label htmlFor="">Force stockout</label>
                <Switch
                  isChecked={!!row.isForceStockout}
                  showPopOver={true}
                  onClick={(isChecked: boolean) =>
                    updateElement({
                      keyName: "isForceStockout",
                      value: isChecked,
                      id: row.id,
                    })
                  }
                />
              </div>

              <div>
                <label htmlFor="">New arrival</label>
                <Switch
                  isChecked={!!row.isNewArrival}
                  showPopOver={true}
                  onClick={(isChecked: boolean) =>
                    updateElement({
                      keyName: "isNewArrival",
                      value: isChecked,
                      id: row.id,
                    })
                  }
                />
              </div>
              <div>
                <label htmlFor="">Featured</label>
                <Switch
                  isChecked={!!row.isFeatured}
                  showPopOver={true}
                  onClick={(isChecked: boolean) =>
                    updateElement({
                      keyName: "isFeatured",
                      value: isChecked,
                      id: row.id,
                    })
                  }
                />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      renderCell: ({ row }: { row: any }) => (
        <div className="flex">
          <IconButton
            onClick={() => {
              const hasPermissionForEdit = userPermissions?.find(userPermission => userPermission === permissions['product'].permissionSet.product.edit || userPermission === "*");
              hasPermissionForEdit ? handleOnCellClick({ row }) : notifyError('permission required')
            }}
          >
            <EditIcon />
          </IconButton>
          <Tooltip arrow title="re-order product images">
            <IconButton className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 rounded-md" onClick={() => {
              setReorderSidebarVisible(true)
              handleOnCellClickReorder({ row })
            }}>
              <ReorderIcon />
            </IconButton>
          </Tooltip>

          <Button className="bg-indigo-500 hover:bg-indigo-600 text-center px-4 text-white ml-2" onClick={() => navigate(`/products/details/${row.code}`)}>
            <span>view</span>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Ont admin - Product list</title>
      </Helmet>

      <ActionBar title="Products">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={e => setSearchTerm(e.target.value)}
            className=" block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          <Tooltip arrow title="filter options">
            <IconButton
              className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 p-1.5"
              onClick={onToggleFilter}
            >
              {showResetAllButton && (
                <div
                  style={{
                    position: "absolute",
                    top: "-0.2rem",
                    right: "-0.2rem",
                    borderRadius: "50%",
                    background: "red",
                    width: "0.7rem",
                    height: "0.7rem",
                  }}
                />
              )}
              <FilterListIcon fontSize="medium" className="text-white" />
            </IconButton>
          </Tooltip>


          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => onReset()}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>

      {!!selectedProduct.length && (
        <Box sx={{ display: "flex", justifyContent: "end" }} m="20px">
          <Button
            className="bg-indigo-400  p-2 text-white"
            onClick={e => onBulkUpdate()}
          >
            Update {selectedProduct.length} items
          </Button>
        </Box>
      )}
      <DataTable
        columns={columns}
        data={products}
        isLoading={isLoading}
        page={page}
        disableColumnMenu={true}
        pageSize={pageSize}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
          selectedProducts={selectedProduct}
          isBulkUpdate={isBulkUpdate}
        />
      )}
      {isFilterModalVisible && (
        <FilterSidebar
          {...values}
          isVisible={true}
          onClose={onCloseFilterModal}
        />
      )}
      {isReorderSidebarVisible && (
        <ReorderSidebar
          data={products.find((product) => product.id === selectedId)}
          isVisible={isReorderSidebarVisible}
          onClose={onCloseReorderSidebar}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}

export default withPermission(ProductList, permissions['product'].permissionSet.product.view) 