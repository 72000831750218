import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { notifyError, notifySuccess } from 'src/generic-ui';
import { couponService } from 'src/services/coupon.service';

export default function CouponField({ setCode }) {
    const [couponCode, setCouponCode] = useState<string>("")
    const checkCouponCodeValidity = async (couponCode: string) => {
        try {
            const response = await couponService.checkCouponValidity({ code: couponCode });
            if (response.statusCode === 200) {
                setCode(response?.data?.code)
                notifySuccess('coupon applied');
            }
        } catch (error: any) {
            if (error.statusCode === 400) {
                notifyError(error.errorMessages);
            } else if (error.statusCode === 500) {
                notifyError('something went wrong');
            }
        }
    };

    const handleChange = (e) => {
        setCouponCode(e.target.value)
    }

    return (
        <div className="mt-10 border-t border-gray-200 pt-8">
            <p className='text-xs mb-5 font-semibold uppercase mt-2'>Coupon code</p>

            <div className="flex">
                <TextField type="text" name="couponCode" onChange={handleChange} value={couponCode} />

                <Button
                    className="bg-indigo-500 hover:bg-indigo-700 text-white ml-2"
                    onClick={() => {
                        checkCouponCodeValidity(couponCode);
                    }}
                >
                    Apply coupon
                </Button>
            </div>
        </div>
	);
}