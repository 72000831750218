import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { notifyError, notifySuccess } from 'src/generic-ui';
import { CustomerSchema } from 'src/schemas/userSchema';
import { logger, userService } from 'src/services';

export default function CreateCustomer() {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: ""
        },
        enableReinitialize: true,
        validationSchema: CustomerSchema,
        async onSubmit(values, action) {
            setSubmitting(true);
            try {
                await userService.createCustomer(values);
                notifySuccess("Customer created successfully");
                action.resetForm()
            }
            catch (error) {
                logger.error(error)
                notifyError(error.message)

            }
            finally {
                setSubmitting(false);
            }
        },
    });
    const { handleSubmit, handleBlur, handleChange, values, errors } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <div className='mb-5'>
                <TextField
                    fullWidth
                    label="first name"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.firstName}
                />
                {errors.firstName ? <small className='text-red-500'>{errors.firstName}</small> : null}
            </div>


            <div className='mb-5'>
                <TextField
                    fullWidth
                    label="last name"
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lastName}
                />
                {errors.lastName ? <small className='text-red-500'>{errors.lastName}</small> : null}
            </div>

            <div className='mb-5'>
                <TextField
                    fullWidth
                    label="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.phoneNumber}
                />
                {errors.phoneNumber ? <small className='text-red-500'>{errors.phoneNumber}</small> : null}
            </div>


            <div className='mb-5'>
                <TextField
                    fullWidth
                    label="email address (optional)"
                    name="email"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.email}
                />
            </div>


            <LoadingButton
                loadingIndicator="Loading..."
                type="submit"
                loading={isSubmitting}
                color="primary"
                className={classNames(
                    "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white",
                )}
            >
                Submit
            </LoadingButton>
        </form>
    )
}
