import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  ACTIVE_DROPDOWN,
  FEATURED_DROPDOWN,
  NEW_ARRIVAL_DROPDOWN,
  STOCK_DROPDOWN,
} from "src/constants/globalConstants";
import CustomDropDown from "src/generic-ui/CustomDropDown";
import CustomPagination from "src/generic-ui/CustomPagination/CustomPagination";
import { useProductFilter } from "src/hooks/products/useProductFilter";
import { IProduct } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import ProductItemCard from "./ProductItemCard";
import { useEffect, useState } from "react";
import { notifyError } from "src/generic-ui";
import classNames from "classnames";

interface ISelectProduct {
  onClick?: (product: IProduct) => void;
  selectedProducts?: string[];
}

const DEFAULT_QUERY: Record<string, QueryParamsType> = {
  limit: 100,
  page: 1,
};

const PAGE_SIZE = 20;
export default function SearchProductsV2({
  onClick,
  selectedProducts,
}: ISelectProduct) {
  const [selectedVariants, setSelectedVariants] = useState<Record<string, string>>({})
  const {
    brands,
    isLoading,
    products,
    categories,
    subCategories,
    departments,
    meta,
    error,
    showResetAllButton,
    onReset,
    total,
    setPageSize,
    selectedBrandId,
    setBrandId,
    setDepartMentId,
    selectedDepartMentId,
    selectedCategoryId,
    setCategoryId,
    selectedSubCategoryId,
    setSubCategoryId,

    selectedActiveStatus,
    setActiveStatus,

    selectedNewArrivalStatus,
    setNewArrivalStatus,
    selectedStockOutStatus,
    setStockOutStatus,
    setFeatureStatus,
    selectedFeatureStatus,
    searchTerm,
    setSearchTerm,
    page,
    setPage,
    pageSize,
    mutate,
  } = useProductFilter();

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {

  }, [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          background: "white",
          position: "sticky",
          top: 0,
          bottom: 20,
          paddingTop: "1rem",
          paddingBottom: "1rem",
          zIndex: 5,
        }}
      >
        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Brand"
              margin="dense"
              placeholder="Select Brand"
              onChange={value => setBrandId(value)}
              value={selectedBrandId}
              name="brand"
            >
              {brands.map((brand, index: number) => {
                return (
                  <MenuItem value={brand.id} key={index}>
                    {brand.title}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
          <Grid item xs={6}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Department"
              margin="dense"
              placeholder="Select Department"
              onChange={value => {
                setDepartMentId(value);
                setCategoryId("");
                setSubCategoryId("");
              }}
              value={selectedDepartMentId}
              name="department"
            >
              {departments.map((department, index: number) => {
                return (
                  <MenuItem value={department.id} key={index}>
                    {department.title}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={6}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Category"
              margin="dense"
              placeholder="Select Category"
              onChange={value => {
                setCategoryId(value);
                setSubCategoryId("");
              }}
              value={selectedCategoryId}
              name="category"
            >
              {categories.map((category, index: number) => {
                return (
                  <MenuItem value={category.id} key={index}>
                    {category.title}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
          <Grid item xs={6}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Sub Category"
              margin="dense"
              placeholder="Select Sub Category"
              onChange={value => setSubCategoryId(value)}
              value={selectedSubCategoryId}
              name="subCategory"
            >
              {subCategories.map((subCategory, index: number) => {
                return (
                  <MenuItem value={subCategory.id} key={index}>
                    {subCategory.title}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
          <Grid item xs={3}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Featured"
              margin="dense"
              placeholder="Featured"
              onChange={value => {
                setFeatureStatus(value);
              }}
              value={selectedFeatureStatus}
              name="isFeatured"
            >
              {FEATURED_DROPDOWN.map((status, index: number) => {
                return (
                  <MenuItem value={status?.value} key={index}>
                    {status?.label}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
          <Grid item xs={3}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Active"
              margin="dense"
              placeholder="Active"
              onChange={value => {
                setActiveStatus(value);
              }}
              value={selectedActiveStatus}
              name="isActive"
            >
              {ACTIVE_DROPDOWN.map((status, index: number) => {
                return (
                  <MenuItem value={status?.value} key={index}>
                    {status?.label}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
          <Grid item xs={3}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="Stock Status"
              margin="dense"
              placeholder="Stock Status"
              onChange={value => {
                setStockOutStatus(value);
              }}
              value={selectedStockOutStatus}
              name="isStockOut"
            >
              {STOCK_DROPDOWN.map((status, index: number) => {
                return (
                  <MenuItem value={status?.value} key={index}>
                    {status?.label}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
          <Grid item xs={3}>
            <CustomDropDown
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              label="New Arrival"
              margin="dense"
              placeholder="New Arrival"
              onChange={value => {
                setNewArrivalStatus(value);
              }}
              value={selectedNewArrivalStatus}
              name="isNewArrival"
            >
              {NEW_ARRIVAL_DROPDOWN.map((status, index: number) => {
                return (
                  <MenuItem value={status?.value} key={index}>
                    {status?.label}
                  </MenuItem>
                );
              })}
            </CustomDropDown>
          </Grid>
        </Grid>
        <TextField
          fullWidth
          label="Products"
          name="searchTerm"
          type="text"
          value={searchTerm}
          onChange={handleChange}
        />

        {!isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            {showResetAllButton ? (
              <Button
                className="bg-red-400 hover:bg-red-800 text-white"
                onClick={e => onReset()}
              >
                Reset All
              </Button>
            ) : (
              <div></div>
            )}

            <CustomPagination
              onPageChange={(nexPageNumber: number) => {
                setPage(nexPageNumber);
              }}
              totalCount={total}
              pageSize={pageSize}
              currentPage={page}
            />
          </Box>
        )}
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !products.length ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          No Products Found
        </Box>
      ) : (
        <Box className="overscroll-auto " height={"auto"}>
          {products.map((product: any, key: number) => {
            const imageUrl =
              product.images?.filter((item: any) => item?.isThumb === true)?.[0]
                ?.link || "";

            const isSelected = !!selectedProducts?.filter(s => s === product.id)
              .length;

            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                className={classNames("py-1 my-2 rounded-md", isSelected? "border-2 border-green-600": "border")}
                key={key}
              >

                <ProductItemCard orderItem={product} config={{ setSelectedVariants }} />

                {!isSelected && (
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      !isSelected && onClick({ ...product, selectedVariants });
                    }}
                  >
                    Add product
                  </Button>
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
