import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { CouponSchemaType } from "src/schemas/couponSchema";
import { logger } from "./logger.service";
import { ICouponCheckRequest, ResponseSccessType } from "src/types";
import axiosInstance from "src/helpers/axios/axiosInstance";

class CouponService {
  private readonly couponUrl = "coupons";
  async createCoupon(coupon: CouponSchemaType) {
    const data: { [key: string]: any } = {};
    Object.keys(coupon).forEach((key) => {
      if (!utils.checkIsNullOrUndefined(coupon[key])) data[key] = coupon[key];
    });
    return await apiPost({ apiPath: this.couponUrl, data });
  }

  async updateCoupon({ id, ...coupon }: CouponSchemaType & { id: string }) {
    const data: { [key: string]: any } = {};
    Object.keys(coupon).forEach((key) => {
      if (!utils.checkIsNullOrUndefined(coupon[key])) data[key] = coupon[key];
    });
    return await apiPatch({ apiPath: `${this.couponUrl}/${id}`, data });
  }

  async deleteCoupon(id: string) {
    return await apiDelete({ apiPath: `${this.couponUrl}/${id}` });
  }

  async checkCouponValidity(
    body: ICouponCheckRequest
  ): Promise<ResponseSccessType> {
    return await axiosInstance().post("/coupons/check-validity", body);
  }
}
export const couponService = new CouponService();
