import qs from "qs";
import { IPaymentMethod } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import useRequest from "../useRequest";
export const usePaymentMethod = (query: Record<string, QueryParamsType>) => {
  const url = `/payment-methods?${qs.stringify(query)}`;
  const { data, mutate, isLoading, error, ...rest } = useRequest(url, url);
  return {
    methods: (data as Array<IPaymentMethod>) || [],
    mutate,
    isLoading,
    error,
    ...rest,
  };
};
