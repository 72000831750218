import { ORDER_LISTS } from "src/constants/apis";
import { apiDelete, apiPatch, apiPost } from "src/helpers/axios/config";
import utils from "src/helpers/Utils";
import { CouponSchemaType } from "src/schemas/couponSchema";
import { IOrderCartSummary, IOrderUpdate, ResponseSccessType } from "src/types";
import { logger } from "./logger.service";
import axiosInstance from "src/helpers/axios/axiosInstance";

class OrderService {
  async createOrder(body: any) {
    try {
      const response: any = await apiPost({
        apiPath: `${ORDER_LISTS}`,
        data: body,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateOrder(id: string, body: IOrderUpdate) {
    try {
      const response: any = await apiPatch({
        apiPath: `${ORDER_LISTS}/${id}`,
        data: body,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateOrderModify(id: string, body: any) {
    try {
      const response: any = await apiPatch({
        apiPath: `${ORDER_LISTS}/${id}/modify`,
        data: body,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateOrderStatus(
    id: string,
    body: {
      orderStatus: string;
      comments: string;
    }
  ) {
    try {
      const response: any = await apiPatch({
        apiPath: `${ORDER_LISTS}/${id}/status`,
        data: body,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async orderCartSummary(body: IOrderCartSummary): Promise<ResponseSccessType> {
    return await axiosInstance().post("/orders/cart-summary", body);
  }
}
export const orderService = new OrderService();
