import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DEBOUNCE_DELAY, DEFAULT_PAGE_SIZE } from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import Switch from "src/generic-ui/Switch";
import { useDebounce } from "src/hooks/useDebounce";
import { bannerService, logger } from "src/services";
import { BrandOrDepartment } from "src/types";
import { QueryParamsType } from "src/types/query-params";
import Sidebar from "./Sidebar";
import withPermission from "src/components/permission/withPermission";
import { permissions } from "src/constants/permissions";
import { Helmet } from "react-helmet-async";
import ActionBar from "src/generic-ui/ActionBar";
import { useBanner } from "src/hooks/cms/useBanners";
import ReorderSidebar from "./ReorderSidebar";
import ReorderIcon from '@mui/icons-material/Reorder';
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const ColValue = ({ value = "" }: { value: any }) => {
  return <Box display="flex">{value}</Box>;
};

function Banner() {
  const [isSidebarVisible, setVisible] = useState<boolean>(false);
  const [isReorderSidebarVisible, setReorderSidebarVisible] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [selectedId, setSelected] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, banners, mutate, error, meta } = useBanner(query);
  const onToggleSideBar = () => setVisible(prev => !prev);
  const onToggleReorderSideBar = () => setReorderSidebarVisible(prev => !prev);

  const [rowCountState, setRowCountState] = useState(meta?.total || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      meta?.total !== undefined ? meta?.total : prevRowCountState,
    );
  }, [meta, setRowCountState]);

  const onClose = () => {
    onToggleSideBar();
    setSelected(null);
  };

  const onCloseReorderSidebar = () => {
    onToggleReorderSideBar();
    setSelected(null);
  };

  const handleOnCellClick = ({ row }: { row: BrandOrDepartment }) => {
    setSelected(row.id);
    onToggleSideBar();
  };

  const onRefresh = () => {
    mutate();
  };

  const updateElement = async ({
    keyName,
    id,
    value,
  }: {
    keyName: string;
    id: string;
    value: any;
  }) => {
    try {
      await bannerService.updateBanner({ id, [keyName]: value });
    } catch (error) {
      logger.error(error);
    } finally {
      onRefresh();
    }
  };

  const columns: any = [
    {
      field: "image",
      headerName: "image",
      flex: 1,
      renderCell: ({ row }: { row: BrandOrDepartment }) => (
        <img src={row.image} className="h-50 w-70" />
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: ({ row }: { row: BrandOrDepartment }) => (
        <ColValue value={row.description} />
      ),
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: ({ row }: { row: BrandOrDepartment }) => (
        <Switch
          isChecked={!!row.isActive}
          showPopOver={true}
          onClick={(isChecked: boolean) =>
            updateElement({ keyName: "isActive", value: isChecked, id: row.id })
          }
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: BrandOrDepartment }) => (
        <IconButton
          onClick={() => {
            handleOnCellClick({ row });
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }

  const onReset = () => {
    setSearchTerm("");
  }

  const showResetAllButton = useMemo(() => {
    return (
      !!debouncedSearchTerm
    )
  }, [debouncedSearchTerm])

  return (
    <>
      <Helmet>
        <title>Ont admin - cms</title>
      </Helmet>

      <ActionBar title="Banner">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={e => setSearchTerm(e.target.value)}
            className=" block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          <Tooltip arrow title="re-order banner images">
            <IconButton className="bg-indigo-500 hover:bg-indigo-600 text-white ml-2 rounded-md" onClick={() => setReorderSidebarVisible(true)}>
              <ReorderIcon />
            </IconButton>
          </Tooltip>
          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset()
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>

      <DataTable
        columns={columns}
        data={banners}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
      {isSidebarVisible && (
        <Sidebar
          isVisible={isSidebarVisible}
          onClose={onClose}
          selectedId={selectedId}
          onRefresh={onRefresh}
        />
      )}

      {isReorderSidebarVisible && (
        <ReorderSidebar
          data={banners}
          isVisible={isReorderSidebarVisible}
          onClose={onCloseReorderSidebar}
          onRefresh={onRefresh}
        />
      )}
    </>

  );
}


export default withPermission(Banner, permissions['cms'].permissionSet.banner.view);