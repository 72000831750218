import { Helmet } from 'react-helmet-async';
import GalleryLayout from 'src/components/gallery/GalleryLayout'
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';

function Gallery() {
    return (
        <div className="mx-2 my-2">
            <Helmet>
                <title>Ont admin - Gallery</title>
            </Helmet>
            <div>
                <h2 className="ml-10 font-bold text-xl mt-5 mb-3">Gallery images</h2>
            </div>
            <GalleryLayout />
        </div>
    )
}

export default withPermission(Gallery, permissions['gallery'].permissionSet.view);