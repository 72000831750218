import { useFormikContext } from 'formik';
import React from 'react'
import { ImagesEntity } from 'src/types';
import DeleteIcon from '@mui/icons-material/Delete';

export default function OrderItemView({ orderItem }: any) {
  const { values, setFieldValue } = useFormikContext<any>();
  const imageLink = orderItem?.product?.images?.filter((image: ImagesEntity) => image.isThumb === true)[0]
    ?.link;
  return (
    <li key={orderItem.id} className="flex py-6 px-4 sm:px-6 border my-2">
      <div className="flex-shrink-0">
        <p
          className="font-medium text-gray-700 hover:text-gray-800"
        >
          <img src={imageLink} alt={''} className="w-16 rounded-md" />
        </p>
      </div>

      <div className="ml-6 flex flex-1 flex-col">
        <div className="flex">
          <div className="min-w-0 flex-1">
            <p
              className="font-medium text-gray-700 hover:text-gray-800"
            >
              <h4 className="text-sm font-bold">{orderItem?.product?.title}</h4>
            </p>

            <div className='my-2'>
              {orderItem?.productVariantOptions.map((el, index) => {
               const data =  orderItem?.product?.variants?.find(variant => variant.id === el);               
               return (
                <div key={index}>
                  {data?.variant?.title} : {data?.variantOption?.title}
                </div>
               )
              })}
            </div>
          </div>
          <div>
            <button
              type="button"
              className="ml-4 font-medium text-ecom-theme sm:ml-0 sm:mt-2"
              onClick={() => {
                const newItem = values.newOrderItems.filter((item) => orderItem.id !== item.id);
                setFieldValue('newOrderItems', newItem)
              }}
            >
              <span>
                <DeleteIcon className="h-5 w-5" />
              </span>
            </button>
          </div>
        </div>

        <div className="flex flex-1 items-end justify-between pt-2">
          <p className="mt-1 text-sm font-medium text-gray-900">৳{orderItem.mrp}</p>

          <div className="ml-4">
            <label htmlFor="quantity" className="sr-only">
              Quantity
            </label>
            x {orderItem.quantity}
          </div>
        </div>
      </div>
    </li>
  )
}
