import { Autocomplete, Grid, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import Spinner from 'src/generic-ui/Spinner';
import { useArea } from 'src/hooks/address/useArea';
import { useCity } from 'src/hooks/address/useCity';
import { QueryParamsType } from 'src/types';
import Area from './Area';
const PAGE_SIZE = 10;

export default function ShippingAddress() {
    const { handleChange, handleBlur, values } = useFormikContext<any>();
    const queryCity: {
        limit: number;
        page: number
    } = {
        limit: PAGE_SIZE,
        page: 1,
    };

    const queryArea: {
        limit: number;
        page: number
    } = {
        limit: PAGE_SIZE,
        page: 1,
    };
    const { isLoading: isLoadingCity, cities, mutate: mutateCity } = useCity(queryCity);
    const { isLoading: isLoadingArea, areas, mutate: mutateArea } = useArea({...queryArea});
    if (isLoadingArea || isLoadingCity) return <Spinner />;

    const modifiedArea = areas.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });


    const defaultAreaOption = modifiedArea.find(
        (option) => values.shippingAddress.area === option.value
    )

    const modifiedCity = cities.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });


    const defaultCityOption = modifiedCity.find(
        (option) => values.shippingAddress.city === option.value
    );
    
    return (
        <>
            <p className='text-xs mb-5 font-semibold uppercase'>Shipping address</p>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="customer name"
                        name="shippingAddress.customerName"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.shippingAddress?.customerName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="phone number"
                        name="shippingAddress.phoneNumber"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.shippingAddress?.phoneNumber}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        className=""
                        defaultValue={defaultCityOption || ""}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        options={modifiedCity}
                        renderInput={(params) => <TextField {...params} label="select city" />}
                        onChange={(event: any, newValue: Record<string, any>) => {
                            handleChange('shippingAddress.city')(newValue.value);
                        }}
                        onBlur={handleBlur}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Area cityId={values.shippingAddress.city} />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        type="email"
                        label="customer email"
                        name="shippingAddress.customerEmail"
                        onChange={handleChange}
                        className="w-full"
                        value={values.shippingAddress.customerEmail}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        rows={5}
                        label="Full address"
                        name="shippingAddress.fullAddress"
                        multiline
                        className="w-full"
                        onChange={handleChange}
                        value={values.shippingAddress.fullAddress}

                    >
                    </TextField>
                </Grid>

            </Grid>
        </>
    )
}
