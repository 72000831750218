import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import SideBarModal from "src/components/modals/SideBarModal";
import Department from "src/components/products/create/catalogs/Department";
import SuspenseLoader from "src/components/SuspenseLoader";
import TextEditor from "src/generic-ui/RichTextEditor";
import { useProductDetails } from "src/hooks/products/useProductDetails";
import { logger } from "src/services";
import { productService } from "src/services/product.service";
import { IModals, IProduct } from "src/types";
import Brands from "../../../components/products/create/catalogs/Brands";
import Categories from "../../../components/products/create/catalogs/Categories";
import SubCategories from "../../../components/products/create/catalogs/SubCategories";
import GalleryLayout from "src/components/gallery/GalleryLayout";
import Popover from "src/generic-ui/Popover";
import _ from "lodash";
import { useSelector } from "react-redux";
import Variants from "./edit/Variants";
import { parsedProductUpdateFormRequest } from "src/transformer";
import ProductImages from "./edit/Images";
import Switch from "src/generic-ui/Switch";
import SpecialCategories from "src/components/products/create/catalogs/SpecialCategories";
import { notifyError } from "src/generic-ui";

interface ISidebar extends IModals {
  selectedId?: string;
  onRefresh?: () => void;
  isBulkUpdate?: boolean;
  selectedProducts?: IProduct[];
}
export default function Sidebar({
  isVisible,
  onClose,
  selectedId,
  onRefresh,
  selectedProducts = [],
  isBulkUpdate = false,
}: ISidebar) {
  const selectedImg: any = useSelector<any>(
    state => state.selectedGalleryImages,
  );
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [department, setDepartment] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const { isLoading, data, mutate, error } = useProductDetails(
    isBulkUpdate ? null : selectedId,
  );
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const transformImageData: any = (images: any[]) => {
    const modifiedImages = images?.map((image, index) => {
      return {
        imageId: image.id,
        idx: index,
        link: image.link,
        isThumb: image.isThumb,
        isDeleted: false,
      };
    });
    return modifiedImages;
  };

  const formik = useFormik({
    initialValues: {
      title: data?.title || "",
      description: data?.description || "",
      specification: data?.specification || "",
      unit: data?.unit || "",
      brand: data?.brandId || "",
      department: data?.departmentId || "",
      category: data?.categoryId || "",
      subCategory: data?.subCategoryId || "",
      images: transformImageData(data?.images) || [],
      variants: data?.variants || [],
      manageVariants: {},
      productStocks: data?.productStocks || [],
      isForceStockout: data?.isForceStockout || false,
      isNewArrival: data?.isNewArrival || false,
      isFeatured: data?.isFeatured || false,
      isActive: data?.isActive || false,
      specialCategories: [],
    },
    enableReinitialize: true,
    onSubmit(values) {
      values.images = values.images.map(image => {
        const imageObject = {};
        imageObject["link"] = image.link;
        imageObject["isThumb"] = image.isThumb;

        if (image.isDeleted === true) {
          imageObject["isDeleted"] = image.isDeleted;
        }
        return imageObject;
      });

      const selectedAllVariants: any = [];
      values.manageVariants &&
        Object.keys(values.manageVariants).forEach((v, a) =>
          selectedAllVariants.push(...values.manageVariants[v]),
        );

      const filteredAllVariantOptions = selectedAllVariants.map(
        variantOption => {
          const obj = { variantOption: variantOption.id };
          if (variantOption.isDeleted) {
            obj["isDeleted"] = variantOption.isDeleted;
          }
          return obj;
        },
      );

      const temp = [...filteredAllVariantOptions];
      values.variants = temp;

      const parsedFormValue = parsedProductUpdateFormRequest({
        ...values,
        isBulk: true,
      });
      !isBulkUpdate
        ? onEditSubmit(parsedFormValue)
        : onBulkUpdate(parsedFormValue);
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors: formikError,
    touched,
    setValues,
    setFieldValue,
  } = formik;

  const [selectedImages, setImages] = useState<{
    images: any[];
  }>({
    images: [],
  });

  useEffect(() => {
    setImages({
      images: [...values?.images] || [],
    });
  }, [values]);

  const modifiedSelectedImages = selectedImages.images.map(
    (selectedImage, index) => {
      return {
        imageId: selectedImage.imageId,
        idx: index,
        link: selectedImage.link,
        isThumb: selectedImage.isThumb,
        isDeleted: selectedImage.isDeleted,
      };
    },
  );

  if (isLoading) {
    return <SuspenseLoader />;
  }

  const onEditSubmit = async (values: any) => {
    try {
      setSubmitting(true);
      await productService.updateProduct(selectedId, values);
    } catch (error) {
      logger.error(error);
    } finally {
      setSubmitting(false);
      onRefresh?.();
      onClose();
    }
  };

  const onBulkUpdate = async (values: any) => {
    try {
      setSubmitting(true);
      await productService.bulkUpdate(selectedProducts, values);
      onRefresh?.();
      onClose();
    } catch (error) {
      logger.log(error);
      notifyError(error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const onSelectedImages = (key: string, value: any) => {
    const modifiedValue = value.map(val => {
      val.isDeleted = false;
      return val;
    });

    const mergedImages = [
      ...selectedImages.images,
      ...modifiedValue.filter(
        newImage =>
          !selectedImages.images.find(image => image.link === newImage.link),
      ),
    ];

    setImages({
      images: mergedImages,
    });
  };

  return (
    <>
      <FormikProvider value={formik}>
        <SideBarModal
          isVisible={isVisible}
          onClose={onClose}
          left={40}
          title={"Edit product"}
        >
          <Box paddingX={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-2">
                  {!isBulkUpdate && (
                    <>
                      <div className="col-span-1">
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          label="Title"
                          name="title"
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {formikError.title && touched.title ? (
                          <p className="text-red-600">{formikError.title}</p>
                        ) : null}
                      </div>
                    </>
                  )}

                  <div className="col-span-1">
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Unit"
                      name="unit"
                      type="text"
                      value={values.unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {formikError.unit && touched.unit ? (
                      <p className="text-red-600">{formikError.unit}</p>
                    ) : null}
                  </div>

                  <div className="col-span-1">
                    <Brands />
                  </div>

                  <div className="col-span-1">
                    <Department
                      departmentConfig={{ department, setDepartment }}
                    />
                  </div>

                  {!!isBulkUpdate && (
                    <div className="col-span-1">
                      <SpecialCategories
                        specialConfig={{ department, setDepartment }}
                      />
                    </div>
                  )}

                  <div className="col-span-1 mt-2">
                    {department ? (
                      <Categories
                        queryConfig={{ department }}
                        categoryConfig={{ category, setCategory }}
                      />
                    ) : null}
                  </div>

                  <div className="col-span-1 mt-2">
                    {category ? (
                      <SubCategories queryConfig={{ category }} />
                    ) : null}
                  </div>
                </div>

                {!isBulkUpdate && (
                  <div className="border-4 border-dashed py-4 text-center my-7">
                    <ProductImages
                      modifiedImages={modifiedSelectedImages}
                      config={{ setOpenGallery }}
                    />
                  </div>
                )}
                {!isBulkUpdate && (
                  <div className="border-4 border-dashed py-4 text-center my-7">
                    <Variants />
                  </div>
                )}

                <div className="mt-5">
                  <TextEditor
                    className="h-[150px] rounded-lg"
                    value={values.description}
                    onChange={value => {
                      handleChange("description")(value);
                    }}
                  />
                  {formikError.description && touched.description ? (
                    <p className="text-red-600">{formikError.title}</p>
                  ) : null}
                </div>

                <div className="my-10 mt-28 mb-24">
                  <TextEditor
                    className="h-[150px] rounded-lg"
                    value={values.specification}
                    onChange={value => {
                      handleChange("specification")(value);
                    }}
                  />
                  {formikError.specification && touched.specification ? (
                    <p className="text-red-600">{formikError.title}</p>
                  ) : null}
                </div>
                {isBulkUpdate && (
                  <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
                    <Grid item xs={4}>
                      <div className="col-span-1">
                        <label htmlFor="">Active</label>
                        <Switch
                          isChecked={values.isActive}
                          onClick={(isChecked: boolean) =>
                            setFieldValue("isActive", isChecked)
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="col-span-1">
                        <label htmlFor="">New Arrival</label>
                        <Switch
                          isChecked={values.isNewArrival}
                          onClick={(isChecked: boolean) =>
                            setFieldValue("isNewArrival", isChecked)
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="col-span-1">
                        <label htmlFor="">Force Stock Out</label>
                        <Switch
                          isChecked={values.isForceStockout}
                          onClick={(isChecked: boolean) =>
                            setFieldValue("isForceStockout", isChecked)
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      <div className="col-span-1">
                        <label htmlFor="">Featured</label>
                        <Switch
                          isChecked={values.isFeatured}
                          onClick={(isChecked: boolean) =>
                            setFieldValue("isFeatured", isChecked)
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}

                <div className="sticky bottom-0 z-30 flex-shrink-0 bg-white border-t">
                  <LoadingButton
                    loadingIndicator="Loading..."
                    type="submit"
                    loading={isSubmitting}
                    color="primary"
                    className={
                      "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white my-2"
                    }
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </Grid>
          </Box>
        </SideBarModal>
        <Popover
          title=""
          width={1000}
          height={800}
          open={openGallery}
          setOpen={setOpenGallery}
          onClose={() => {
            setOpenGallery(false);
          }}
        >
          <GalleryLayout
            isOpenFromModal
            setFieldValue={onSelectedImages}
            modalConfig={{ open: openGallery, setOpen: setOpenGallery }}
          />
        </Popover>
      </FormikProvider>
    </>
  );
}
