import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authService, logger } from "src/services";
import { Helmet } from "react-helmet-async";
import { notifyError, notifySuccess } from "src/generic-ui";
import { useFormik } from "formik";
import LockResetIcon from '@mui/icons-material/LockReset';

export default function ResetPassword() {
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const hashString = searchParams.get('str');
    const navigate = useNavigate();

    const { handleBlur, handleChange, values, handleSubmit } =
        useFormik({
            initialValues: {
                email: email || "",
                hash: hashString || "",
                newPassword: "",
                otp: 0
            },
            onSubmit: async (values, helper) => {
                try {
                    values.otp = Number(values.otp);
                    const response = await authService.resetPasswordVerify({ ...values });
                    navigate("/login", { replace: true });
                    notifySuccess("password reset successfully")
                } catch (error) {
                    notifyError(error.message)
                    logger.error(error.message);
                }
            },
        });

    return (
        <>
            <Helmet>
                <title>Ont admin - reset password</title>
            </Helmet>

            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "purple" }}>
                    <LockResetIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset password
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="new-passwrd"
                        label="New password"
                        name="newPassword"
                        autoComplete="newPassword"
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        sx={{
                            '& fieldset': {
                                borderRadius: '5px',
                            },
                        }}
                    />

                    <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        required
                        fullWidth
                        id="otp"
                        label="OTP number"
                        name="otp"
                        autoComplete="otp"
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{
                            '& fieldset': {
                                borderRadius: '5px',
                            },
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        className="bg-black hover:bg-gray-700 transition hover:duration-1000 rounded-sm"
                    >
                        reset and verify
                    </Button>
                </form>
            </Box>


        </>
    );
}
