import { ImagesEntity } from 'src/types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import OrderItemQuantity from '../order/details/order-items/OrderItemQuantity';
import Variants from '../order/details/order-items/Variants';

interface INewOrderItemCard {
    orderItem: any;
    config?: {
        setSelectedVariants: Dispatch<SetStateAction<Record<string, string>>>;
    };
}

export default function ProductItemCard({ orderItem, config }: INewOrderItemCard) {
    const [selectedVariants, setVariants] = useState({})
    const imageLink = orderItem?.images?.filter((image: ImagesEntity) => image.isThumb === true)[0]
        ?.link;

    useEffect(() => {
        config.setSelectedVariants(selectedVariants)
    }, [selectedVariants, config.setSelectedVariants]);


    return (
        <li key={orderItem.id} className="flex py-6 px-4 sm:px-6 my-2">
            <div className="flex-shrink-0">
                <p
                    className="font-medium text-gray-700 hover:text-gray-800"
                >
                    <img src={imageLink} alt={''} className="w-16 rounded-md" />
                </p>
            </div>

            <div className="ml-6 flex flex-1 flex-col">
                <div className="flex">
                    <div className="min-w-0 flex-1">
                        <p
                            className="font-medium text-gray-700 hover:text-gray-800"
                        >
                            <h4 className="text-sm font-bold">{orderItem?.title}</h4>
                        </p>

                        <div className='my-2'>
                            {orderItem?.productStocks?.map((productStock, index) => {
                                return (
                                    <div key={index}>
                                        <Variants productStock={productStock} isNewItem={true} config={{ selectedVariants, setVariants }} />
                                    </div>
                                )
                            })}
                        </div>
                        <OrderItemQuantity quantity={1} isNewAdded={true} />
                    </div>
                </div>

                <div className="flex flex-1 items-end justify-between pt-2">
                    <p className="mt-1 text-sm font-medium text-gray-900">৳{orderItem.mrp}</p>
                </div>
            </div>
        </li>
    )
}
