import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useArea } from 'src/hooks/address/useArea';
const PAGE_SIZE = 10;
interface IArea {
    cityId: string;
}
export default function Area({ cityId }: IArea) {
    const { values, handleChange, handleBlur } = useFormikContext<any>();
    const queryArea: {
        limit: number;
        page: number;
        city?: string;
    } = {
        limit: PAGE_SIZE,
        page: 1,
        city: cityId
    };
    const { isLoading: isLoadingArea, areas, mutate: mutateArea } = useArea({ ...queryArea });
    const modifiedArea = areas.filter((department) => department.isActive === true).map((activeDepartment) => {
        return {
            label: activeDepartment.title,
            value: activeDepartment.id
        }
    });


    const defaultAreaOption = modifiedArea.find(
        (option) => values.shippingAddress.area === option.value
    )
    return (
        <>
            <Autocomplete
                disabled={!cityId}
                id="combo-box-demo"
                fullWidth
                className=""
                defaultValue={defaultAreaOption || ""}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                options={modifiedArea}
                renderInput={(params) => <TextField {...params} label="select area" />}
                onChange={(event: any, newValue: Record<string, any>) => {
                    handleChange('shippingAddress.area')(newValue.value);
                }}
                onBlur={handleBlur}
            />
        </>
    )
}
