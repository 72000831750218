import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { DEBOUNCE_DELAY, DEFAULT_PAGE_SIZE } from "src/constants/globalConstants";
import { DataTable } from "src/generic-ui/DataTable";
import { useDebounce } from "src/hooks/useDebounce";
import { QueryParamsType } from "src/types/query-params";
import { permissions } from "src/constants/permissions";
import withPermission from "src/components/permission/withPermission";
import { Helmet } from "react-helmet-async";
import ActionBar from "src/generic-ui/ActionBar";
import { useFeedback } from "src/hooks/feedback/useFeedback";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

function Feedback() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const debouncedSearchTerm = useDebounce({
    searchQuery: searchTerm,
    delay: DEBOUNCE_DELAY,
  });
  const query: Record<string, QueryParamsType> = {
    limit: pageSize,
    page: page + 1,
  };
  if (!!debouncedSearchTerm) query["searchTerm"] = debouncedSearchTerm;

  const { isLoading, feedbacks, mutate, error, meta } =
    useFeedback(query);
  const [rowCountState, setRowCountState] = useState(meta?.total || 0);
  useEffect(() => {
    setRowCountState(prevRowCountState =>
      meta?.total !== undefined ? meta?.total : prevRowCountState,
    );
  }, [meta, setRowCountState]);

  const columns: any = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },

    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
    },

    {
      field: "feedback",
      headerName: "Feedback",
      flex: 2,
    },
  ];
  if (error) {
    return <div>Something Went Wrong</div>;
  }


  const onReset = () => {
    setSearchTerm("");
  }

  const showResetAllButton = useMemo(() => {
    return (
      !!debouncedSearchTerm
    )
  }, [debouncedSearchTerm])

  return (
    <>
      <Helmet>
        <title>Ont admin - Feedback</title>
      </Helmet>
      <ActionBar title="Feedback">
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <input
            value={searchTerm}
            placeholder="Search"
            onChange={e => setSearchTerm(e.target.value)}
            className=" block rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
          />
          {showResetAllButton && (
            <Tooltip arrow title="reset filter">
              <IconButton
                className="bg-red-500 hover:bg-red-600 text-white ml-2 p-1.5"
                onClick={() => {
                  onReset()
                }}
              >
                <RestartAltIcon fontSize="medium" className="text-white" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ActionBar>
      <DataTable
        columns={columns}
        data={feedbacks}
        isLoading={isLoading}
        page={page}
        pageSize={pageSize}
        disableColumnMenu={true}
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        rowCountState={rowCountState}
      />
    </>

  );
}


export default withPermission(Feedback, permissions['others'].permissionSet.feedback.view);