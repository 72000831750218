import { Button, Popover, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import ActionBar from 'src/generic-ui/ActionBar';
import DatePicker from 'src/generic-ui/DatePicker';
import { ModifiedComingSoonForPage } from 'src/router';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dateUtils from 'src/helpers/utility/DateUtils';
import { useOrderCountByPaymentStatus } from 'src/hooks/dashboard/useOrderCountByPaymentStatus';
import { QueryParamsType } from 'src/types';
import { useOrderCountByStatus } from 'src/hooks/dashboard/useOrderCountByStatus';
import Spinner from 'src/generic-ui/Spinner';
import PaymentStatusCount from './PaymentStatusCount';
import OrderStatusCount from './OrderStatusCount';
import BasicTable from 'src/generic-ui/BasicTable';
import MostOrderedProduct from './MostOrderedProduct';
import MostRatedProduct from './MostRatedProducts';
import MostViewedProduct from './MostViewedProducts';

function Dashboard() {
  const [dates, setDates] = useState({ validFrom: "", validTill: "" });


  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;




  return (
    <>
      <Helmet>
        <title>Ont admin - Dashboard</title>
      </Helmet>
      {/* <ModifiedComingSoonForPage /> */}
      <ActionBar title="Dashboard">
        <>
          <div className='flex'>
            <span className='ml-1'>From: <b>{dates.validFrom ? dateUtils.formatDateTime(dates.validFrom) : "-"}</b></span>
            <span className='ml-3'>Till: <b>{dates.validTill ? dateUtils.formatDateTime(dates.validTill) : "-"}</b> </span>
            <button onClick={handleClick} className='text-ecom-theme flex ml-2'>
              select date ranges
              <KeyboardArrowDownIcon className='h-4 w-4 mt-1 stroke-black' />
            </button>
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            className='px-10'
          >
            <div className='flex'>
              <div className="mx-2">
                <DatePicker
                  label="Valid From"
                  value={dates?.validFrom || ""}
                  onChange={value => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        validFrom: value,
                      }
                    });
                  }}
                  className="mt-4"
                />
              </div>
              <div className='mx-2'>
                <DatePicker
                  label="Valid Till"
                  value={dates?.validFrom || ""}
                  onChange={value => {
                    setDates((prev) => {
                      return {
                        ...prev,
                        validTill: value,
                      }
                    });
                  }}
                  className="mt-4"
                />
              </div>
            </div>
          </Popover>
        </>
      </ActionBar>

      <div className='grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-2 gap-5 my-5'>
        <div className="col-span-1">
          <PaymentStatusCount dates={dates} />
        </div>
        <div className="col-span-1">
          <OrderStatusCount dates={dates} />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-5 my-5'>
        <div className="col-span-1">
          <div className='my-5'>
            <MostOrderedProduct />
          </div>
        </div>
        <div className="col-span-1">
          <div className='my-5'>
            <MostRatedProduct />
          </div>
        </div>
        <div className="col-span-2">
          <div className='my-5'>
            <MostViewedProduct />
          </div>
        </div>
      </div>
    </>
  )
}

export default withPermission(Dashboard, permissions['home'].permissionSet.dashboard.view);