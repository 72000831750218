import { createContext, useRef } from "react";
import dateUtils from "src/helpers/utility/DateUtils";
import { IOrderItem } from "src/types";
import "./Details.css";
import { getSiteLogoUrl } from "src/helpers/configs/envConfig";

interface IContent {
  data: IOrderItem;
  invoiceRef?: any;
}

export default function Content({ data, invoiceRef }: IContent) {
  
  return (
    <div className="wrapper-invoice" ref={invoiceRef}>
      <div className="invoice">
        <div className="invoice-information">
          <p><b>Invoice #</b> : {data?.code} </p>
          <p><b>Created Date </b>: {dateUtils.formatDateTime(data?.createdAt)}</p>
        </div>
        <div className="invoice-logo-brand">
          <img src={getSiteLogoUrl()} />
        </div>
        <div className="invoice-head">
          <div className="head client-info">
            <p>{data?.address?.customerName}</p>
            <p>{data?.address?.phoneNumber}</p>
            <p>{data?.address?.customerEmail}</p>
          </div>
          <div className="head client-data">
            <p>-</p>
            <p>{data?.address?.customerName}</p>
            <p>{data?.address?.city?.title}</p>
            <p>{data?.address?.area?.title}</p>
            <p>{data?.address?.fullAddress}</p>
          </div>
        </div>
        <div className="invoice-body">
          <table className="table">
            <thead>
              <tr>
                <th>Item Description</th>
                <th>Code</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {data.orderItems.map((orderItem: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{orderItem?.product?.title}</td>
                    <td>{orderItem?.product?.code}</td>
                    <td>৳{orderItem?.mrp}</td>
                    <td>{orderItem?.quantity}</td>
                    <td>৳{orderItem?.quantity * orderItem?.mrp}</td>
                  </tr>
                )
              })}

            </tbody>
          </table>
          <div className="flex-table">
            <div className="flex-column" />
            <div className="flex-column">
              <table className="table-subtotal">
                <tbody>
                  <tr>
                    <td>Subtotal</td>
                    <td>৳{data?.subTotal}</td>
                  </tr>
                  <tr>
                    <td>Delivery Charge</td>
                    <td>৳{data?.deliveryCharge}</td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td>৳{data?.discount}</td>
                  </tr>
                  <tr>
                    <td>Coupon Discount</td>
                    <td>৳{data?.couponDiscount}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          {/* invoice total  */}
          <div className="invoice-total-amount">
            <p>Total : ৳{data?.total}</p>
          </div>
        </div>
        {/* invoice footer */}
        <div className="invoice-footer">
          <p>Thank you, happy shopping again</p>
        </div>
      </div>
    </div>
  );
}
