import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid } from '@mui/material'
import classNames from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react'
import SearchProducts from 'src/components/SearchProducts/SearchProducts';
import SideBarModal from 'src/components/modals/SideBarModal';
import NewOrderItemCard from 'src/components/order/details/order-items/NewOrderItemCard';
import SearchUser from 'src/components/user/SearchUser';
import BreadCrumbs from 'src/generic-ui/BreadCrumbs'
import { logger } from 'src/services';
import { parsedCreateOrderRequestBody, parsedNewlyAddedProduct, parsedProductsForAddedOrderItem, parsedProductsForNewAddedOrderItem } from 'src/transformer';
import { IProduct } from 'src/types';
import ShippingAddress from './shipping-address/ShippingAddress';
import PaymentMethods from './PaymentMethods';
import { orderService } from 'src/services/order.service';
import { notifyError, notifySuccess } from 'src/generic-ui';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import { Helmet } from 'react-helmet-async';
import OrderSummary from './OrderSummary';
import CouponField from './CouponCode';
import CreateCustomer from './CreateCustomer';
import SearchProductsV2 from 'src/components/SearchProducts/SearchProductsV2';
import OrderItemView from './OrderItemView';
import { permissions } from 'src/constants/permissions';
import withPermission from 'src/components/permission/withPermission';
import ActionBar from 'src/generic-ui/ActionBar';

function CreateOrder() {
    const [isEditPermissionSidebarVisible, setEditPermissionSidebarVisible] = useState<boolean>(false);
    const [isOpenCreateCustomerModal, setOpenCreateCustomerModal] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<any>({});
    const [isAddProductModalVisible, setModalVisible] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [orderSummary, setOrderSummary] = useState({});
    const [couponCode, setCouponCode] = useState<string>("")

    const onToggleModal = () => setEditPermissionSidebarVisible(prev => !prev);
    const onToggleAddProducModal = () => setModalVisible(prev => !prev);
    const onToggleCreateCustomerModal = () => setOpenCreateCustomerModal(prev => !prev);

    const onCloseModal = () => {
        onToggleModal();
    };

    const onCloseAddProductModal = () => {
        onToggleAddProducModal()
    }

    const onCloseCreateCustomerModal = () => {
        onToggleCreateCustomerModal()
    }

    const onSelectUser = (data) => {
        setSelectedCustomer(data)
    }

    const formik = useFormik({
        initialValues: {
            user: selectedCustomer?.id || "",
            couponCode: "",
            paymentMethod: "",
            shippingAddress: {
                customerName: selectedCustomer?.firstName && selectedCustomer?.lastName ? selectedCustomer?.firstName + " " + selectedCustomer?.lastName : "",
                customerEmail: selectedCustomer?.email || "",
                phoneNumber: selectedCustomer?.phoneNumber || "",
                fullAddress: "",
                city: "",
                area: "",
            },
            products: [],
            newOrderItems: []
        },
        enableReinitialize: true,
        async onSubmit(values, action) {
            setSubmitting(true);
            try {
                logger.log(values)
                const parsedItems = values?.newOrderItems?.map(el =>
                    parsedNewlyAddedProduct(el),
                );
                values.products = parsedItems;
                const parsedValues = parsedCreateOrderRequestBody(values)
                const response = await orderService.createOrder(parsedValues);
                notifySuccess("Order created successfully")
                setOpen(true);
                action.resetForm();
                setSelectedCustomer({})
            }
            catch (error) {
                logger.error(error)
                notifyError(error.message)

            }
            finally {
                setSubmitting(false);
            }
        },
    });

    const {
        values,
        handleSubmit,
        setFieldValue
    } = formik;

    const onSelectProduct = (product: any) => {
        const modifiedProducts: any = parsedProductsForAddedOrderItem(product);
        if (Object.values(product.selectedVariants).some(
            value => value === ''
        )) {
            notifyError('Must select all variants')
        }
        else {
            let preProduct = [...values?.newOrderItems];
            let newList = Object.assign([], preProduct);
            newList.push({ ...modifiedProducts });
            setFieldValue("newOrderItems", newList);
            notifySuccess("product added successfully");
        }
    };

    useEffect(() => {
        const fetchOrderSummary = async () => {
            try {
                const modifiedItems: any = values?.newOrderItems?.map((item: IProduct) => {
                    const productVariants = parsedNewlyAddedProduct(item)
                    return productVariants;
                });
                if (modifiedItems.length > 0) {
                    const response = await orderService.orderCartSummary({
                        products: modifiedItems,
                        couponCode
                    });
                    setOrderSummary(response?.data);
                }

            } catch (error: any) {
                logger.error(error);
            }
        };

        if (values?.newOrderItems?.length > 0) {
            fetchOrderSummary();
        } else {
            setOrderSummary({})
        }
    }, [values?.newOrderItems, couponCode]);

    return (
        <>
            <Helmet>
                <title>Ont admin - Order create</title>
            </Helmet>
            <ActionBar title="Create order">
                <>
                    <Button className=' bg-indigo-500 text-white hover:bg-indigo-700' onClick={() => setEditPermissionSidebarVisible(true)}>Filter customer</Button>
                    {isEditPermissionSidebarVisible && (
                        <SideBarModal
                            isVisible={isEditPermissionSidebarVisible}
                            onClose={onCloseModal}
                            left={60}
                            allowBackdrop={false}
                            title="Customer"
                            renderEndIcon={
                                <>
                                    <Button className='mr-2 bg-indigo-500 text-white hover:bg-indigo-700 h-8 ml-auto' onClick={() => setOpenCreateCustomerModal(true)}>create customer</Button>
                                    {isOpenCreateCustomerModal ? <>
                                        <SideBarModal
                                            isVisible={isOpenCreateCustomerModal}
                                            onClose={onCloseCreateCustomerModal}
                                            left={70}
                                            allowBackdrop={false}
                                            title="Create customer"
                                        >
                                            <Box p="0.5rem">
                                                <CreateCustomer />
                                            </Box>
                                        </SideBarModal>
                                    </> : null}
                                </>
                            }
                        >
                            <Box p="0.5rem">
                                <SearchUser
                                    onClick={onSelectUser}
                                    selectedUser={selectedCustomer}
                                />
                            </Box>
                        </SideBarModal>
                    )}
                </>
            </ActionBar>
            <div className='mt-5'>
                <FormikProvider value={formik}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <ShippingAddress />
                                <div className='mt-10 border-t pt-5'>
                                    <PaymentMethods />
                                </div>
                                {values.newOrderItems.length > 0 ? <CouponField setCode={setCouponCode} /> : null}
                            </Grid>

                            <Grid item xs={4}>
                                <div className=''>
                                    <div className='flex border pt-3 px-3 rounded-md'>
                                        <p className='text-xs mb-5 font-semibold uppercase mt-2'>Order items</p>
                                        <Button className={classNames(' text-white hover:bg-indigo-700 h-8 ml-auto', Object.entries(selectedCustomer).length === 0 ? "bg-indigo-200" : "bg-indigo-500")} onClick={() => setModalVisible(true)} disabled={Object.entries(selectedCustomer).length === 0}>Add product</Button>
                                    </div>
                                    <ul role="list" className="">
                                        {values?.newOrderItems.length > 0 ? <>
                                            {values?.newOrderItems?.map(
                                                (orderItem: IProduct, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <OrderItemView orderItem={orderItem} />
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </> : <div className='flex justify-center py-20 border my-3 rounded-md'>
                                            <MoodBadIcon className='h-4 w-4 mr-1 mt-1' />
                                            <span>
                                                No product added yet
                                            </span>
                                        </div>}
                                    </ul>
                                    <div>
                                        <OrderSummary orderSummary={orderSummary} />
                                    </div>
                                </div>
                                {isAddProductModalVisible && (
                                    <SideBarModal
                                        isVisible={isAddProductModalVisible}
                                        onClose={onCloseAddProductModal}
                                        left={60}
                                        allowBackdrop={false}
                                        title="Add Product"
                                    >
                                        <Box p="0.5rem">
                                            <SearchProductsV2
                                                onClick={onSelectProduct}
                                                selectedProducts={[
                                                    ...values?.newOrderItems,
                                                ]?.map(p => p.product.id)}
                                            />
                                        </Box>
                                    </SideBarModal>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loadingIndicator="Loading..."
                                    type="submit"
                                    loading={isSubmitting}
                                    color="primary"
                                    className={classNames(
                                        "bg-indigo-600 hover:bg-indigo-800 mt-2 text-white",
                                    )}
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </FormikProvider>
            </div>
        </>
    )
}

export default withPermission(CreateOrder, permissions['order'].permissionSet.order.create);