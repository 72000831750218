import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { usePaymentMethod } from 'src/hooks/businessConfig/usePaymentMethods';
import Spinner from 'src/generic-ui/Spinner';
import { QueryParamsType } from 'src/types';
import { useFormikContext } from 'formik';

export default function PaymentMethods() {
    const { handleChange, values } = useFormikContext<any>();
    const query: Record<string, QueryParamsType> = { limit: 10, page: 1 };
    const { methods: paymentMethods, isLoading, mutate } = usePaymentMethod(query);
    if (isLoading) return <Spinner />
    return (
        <FormControl>
            <p className='text-xs mb-5 font-semibold uppercase mt-2'>Payment methods</p>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="paymentMethod"
                value={values.paymentMethod}
                onChange={handleChange}
            >

                {paymentMethods.map((paymentMethod, index) => {
                    return (
                        <React.Fragment key={index}>
                            <FormControlLabel
                                value={paymentMethod.id}
                                control={<Radio />} label={paymentMethod?.title} 
                            />
                        </React.Fragment>
                    )
                })}
            </RadioGroup>
        </FormControl>
    );
}