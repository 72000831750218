import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { useFormikContext } from 'formik'

export default function BasicInfo() {
    const { handleChange, handleBlur, values, setFieldValue, errors, touched } = useFormikContext<any>();
    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Title"
                            name="title"
                            type="text"
                            value={values?.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {errors.title && touched.title ? (
                            <p className="text-red-600">{errors.title}</p>
                        ) : null}

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Lifting price"
                            name="liftingPrice"
                            type="number"
                            value={values?.liftingPrice}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        {errors.liftingPrice && touched.liftingPrice ? (
                            <p className="text-red-600">{errors.liftingPrice}</p>
                        ) : null}

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Lifting price vat"
                            name="liftingPriceVat"
                            type="number"
                            value={values?.liftingPriceVat}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        {errors.liftingPriceVat && touched.liftingPriceVat ? (
                            <p className="text-red-600">{errors.liftingPriceVat}</p>
                        ) : null}

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="stock"
                            name="stock"
                            type="number"
                            value={values?.stock}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        {errors.stock && touched.stock ? (
                            <p className="text-red-600">{errors.stock}</p>
                        ) : null}

                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Mrp"
                            name="mrp"
                            type="number"
                            value={values?.mrp}
                            onChange={handleChange}
                        />

                        {errors.mrp && touched.mrp ? (
                            <p className="text-red-600">{errors.mrp}</p>
                        ) : null}

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Mrp vat"
                            name="mrpVat"
                            type="number"
                            value={values?.mrpVat}
                            onChange={handleChange}
                        />

                        {errors.mrpVat && touched.mrpVat ? (
                            <p className="text-red-600">{errors.mrpVat}</p>
                        ) : null}

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values?.manageCustomInventory} name="manageCustomInventory"
                                    onChange={handleChange}
                                />
                            }
                            label="Custom enventory"
                        />

                        {errors.makeCustomEnventory && touched.makeCustomEnventory ? (
                            <p className="text-red-600">{errors.makeCustomEnventory}</p>
                        ) : null}
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}
