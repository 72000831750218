import React from 'react'
import withPermission from 'src/components/permission/withPermission';
import { permissions } from 'src/constants/permissions';
import ActionBar from 'src/generic-ui/ActionBar';
import { ModifiedComingSoonForPage } from 'src/router';

function Report() {
  return (
    <>
    <ActionBar title="Report"></ActionBar>
        <ModifiedComingSoonForPage />
    </>
  )
}

export default withPermission(Report, permissions['home'].permissionSet.report.view);