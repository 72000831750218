import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import withPermission from "src/components/permission/withPermission";
import BasicInfo from "src/components/products/create/BasicInfo";
import Catalogs from "src/components/products/create/catalogs";
import DescAndSpec from "src/components/products/create/desc-and-spec";
import UploadImages from "src/components/products/create/upload-image";
import Variants from "src/components/products/create/variants";
import { permissions } from "src/constants/permissions";
import ActionBar from "src/generic-ui/ActionBar";
import AlertNotification from "src/generic-ui/AlertNotification";
import StepperComponent from "src/generic-ui/StepperComponent";
import { productCreateSchema } from "src/schemas";
import { productService } from "src/services/product.service";
import { parsedProductFormRequest } from "src/transformer";
import { QueryParamsType } from "src/types/query-params";

export interface IInitialProductForm {
  title: string;
  description: string;
  specification: string;
  liftingPrice: number;
  liftingPriceVat: number;
  mrp: number;
  mrpVat: number;
  stock: number;
  manageCustomInventory: boolean;
  brand: string;
  department: string;
  category: string;
  subCategory: string;
  images?: (ImagesEntity)[] | null;
  variants?: (VariantsEntity)[] | null;
  variantLabels?: any;
  manageVariants: Record<string,any>;
}
export interface ImagesEntity {
  link: string;
  isThumb: boolean;
}
export interface VariantsEntity {
  variantOption: string;
}


const CreateProduct = () => {
  const [open, setOpen] = useState<boolean>(false);
  const query: Record<string, QueryParamsType> = {
    limit: 10,
    page: 1
  }
  const initialProductFormValue: IInitialProductForm = {
    title: "",
    description: "",
    specification: "",
    liftingPrice: 0,
    liftingPriceVat: 0,
    mrp: 0,
    mrpVat: 0,
    stock: 0,
    manageCustomInventory: false,
    brand: "",
    department: "",
    category: "",
    subCategory: "",
    variants: [],
    images: [],
    variantLabels: [],
    manageVariants: {},
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <BasicInfo />;
      case 1:
        return <DescAndSpec />;
      case 2:
        return <Catalogs />;
      case 3:
        return <UploadImages />;
      case 4:
        return <Variants />;
      default:
        return "unknown step";
    }
  }

  const createProducts = async (values) => {
    const selectedAllVariants: any = []
    values.manageVariants && Object.keys(values.manageVariants).forEach((v, a) => selectedAllVariants.push(...values.manageVariants[v]));

    const filteredAllVariantOptions = selectedAllVariants.map((variantOption) => {
      return {
        variantOption: variantOption.value
      }
    })

    const temp = [...filteredAllVariantOptions]
    values.variants = temp;

    values.images = values.images.map((image) => {
      return {
        link: image.link,
        isThumb: image.isThumb
      }
    })

    const parsedFormValues = parsedProductFormRequest(values);
    try {
      const response = await productService.createProducts(parsedFormValues);
      setOpen(true);
      localStorage.removeItem('create-product-form-info');
      localStorage.removeItem('steper-step');
    }
    catch (error) {
      console.error(error)
    }
  }

  const steps = [
    'Basic info',
    'Description & Specification',
    'Catalogs',
    'Upload images',
    'Variants',
  ];


  useEffect(() => {
    return () => {
      localStorage.removeItem('create-product-form-info');
      localStorage.removeItem('stepper-step');
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Ont admin - Create product</title>
      </Helmet>

      <ActionBar title="Create product"/>

      <div className="my-3 mx-20">
        <AlertNotification message="Product is created successfully" open={open} setOpen={setOpen} />
      </div>

      <StepperComponent
        steps={steps}
        getStepContent={getStepContent}
        initialFormValue={initialProductFormValue}
        persistKeyName="create-product-form-info"
        validationSchema={productCreateSchema}
        navigateLink="/products"
        onSubmitHandler={(values) => {
          createProducts(values);
          localStorage.removeItem('create-product-form-info')
        }}
      />
    </>
  );
};


export default withPermission(CreateProduct, permissions['product'].permissionSet.product.create) ;
