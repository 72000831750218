import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import withPermission from 'src/components/permission/withPermission';
import BasicInfo from 'src/components/products/details/BasicInfo';
import Catalogs from 'src/components/products/details/Catalogs';
import DescAndSpec from 'src/components/products/details/DescAndSpec';
import ProductImages from 'src/components/products/details/ProductImages';
import VariantAndVariantOpt from 'src/components/products/details/VariantAndVariantOpt';
import { permissions } from 'src/constants/permissions';
import ActionBar from 'src/generic-ui/ActionBar';
import BreadCrumbs from 'src/generic-ui/BreadCrumbs';
import Spinner from 'src/generic-ui/Spinner';
import CustomTab from 'src/generic-ui/Tab';
import { useProductDetails } from 'src/hooks/products/useProductDetails';
import { useProductDetailsByCode } from 'src/hooks/products/useProductDetailsByCode';

function ProductDetails() {
    const { code } = useParams();
    const { data, isLoading } = useProductDetailsByCode(code);

    if (isLoading) return <Spinner />;



    return (
        <>
            <Helmet>
                <title>Ont admin - Product details</title>
            </Helmet>
    
            <ActionBar title={`Product details (${code})`}></ActionBar>

            <div className='my-5'>
                <div className='border px-5 py-3 rounded-md'>
                    <p className='font-semibold text-base'>Basic info</p>
                    <BasicInfo productDetails={data} />
                </div>
            </div>

            <div className='my-5'>
                <div className='border px-5 py-3 rounded-md'>
                    <p className='font-semibold text-base mb-5'>Description & Specification</p>
                    <DescAndSpec description={data?.description} specification={data?.specification} />
                </div>
            </div>

            <div className='my-5'>
                <div className='border px-5 py-3 rounded-md'>
                    <p className='font-semibold text-base'>Catalogs</p>
                    <Catalogs productDetails={data} />
                </div>
            </div>

            <div className='my-5'>
                <div className='border px-5 py-3 rounded-md'>
                    <p className='font-semibold text-base'>Product images</p>
                    <ProductImages images={data?.images} />
                </div>
            </div>

            <div className='my-5'>
                <div className='border px-5 py-3 rounded-md'>
                    <p className='font-semibold text-base'>Variants & variant options</p>
                    <VariantAndVariantOpt productStocks={data?.productStocks} />
                </div>
            </div>
        </>
    )
}

export default withPermission(ProductDetails, permissions['product'].permissionSet.product.details);