import * as Yup from "yup";

export const productCreateSchema = Yup.object({
  title: Yup.string().required("Please enter product title"),
  description: Yup.string().required("Please enter product description"),
  specification: Yup.string().required("Please enter product specification"),
  liftingPrice: Yup.number().required("Please enter lifting price"),
  liftingPriceVat: Yup.number().required("Please enter lifting price vat"),
  mrp: Yup.number().required("Please enter mrp"),
  mrpVat: Yup.number().required("Please enter mrp vat"),
  stock: Yup.number().required("Please enter total stock"),
  manageCustomInventory: Yup.boolean()
    .default(false)
    .required("please on/off manage custom inventry"),
  brand: Yup.string().required("please select a brand"),
  department: Yup.string().required("please select a department"),
  category: Yup.string().required("please select a category"),
  subCategory: Yup.string().required("please select a sub category"),
  images: Yup.array(
    Yup.object({
      link: Yup.string().required("please add link"),
      isThumb: Yup.boolean().required("please select thumbnail"),
    }),
  )
    .min(1)
    .max(5)
    .required("Please add atleast one image"),
});

export const VariantSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const VariantOptSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  variant: Yup.string().required("Variant is required"),
});

export const CampaignSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  validFrom: Yup.string().required("Starting Date is required"),
  validTill: Yup.string().required("Ending Date is required"),
  discountType: Yup.string().required("Discount Type is required"),
  value: Yup.number()
    .required("Value is Required")
    .min(1, "The minimum amount is one"),
  products: Yup.array().optional(),
  status: Yup.string().optional(),
});

export const StockSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  reason: Yup.string().optional(),
  items: Yup.array().of(
    Yup.object().shape({
      product: Yup.object({
        id: Yup.string().required(),
      }),
      newStock: Yup.number(),
    }),
  ),
  status: Yup.string().optional(),
});

export const PriceSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  reason: Yup.string().optional(),
  items: Yup.array().of(
    Yup.object().shape({
      product: Yup.object({
        id: Yup.string().required(),
      }),
      newMrp: Yup.number(),
      newMrpVat: Yup.number(),
    }),
  ),
  status: Yup.string().optional(),
});

export type PriceSchemaType = Yup.InferType<typeof PriceSchema>;
export type StockSchemaType = Yup.InferType<typeof StockSchema>;
export type CampaignSchemaType = Yup.InferType<typeof CampaignSchema>;
export type VariantSchemaType = Yup.InferType<typeof VariantSchema>;
export type VariantOptSchemaType = Yup.InferType<typeof VariantOptSchema>;
